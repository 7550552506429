<template>
  <div class="section-outer">
    <div class="section-inner">
      <router-link to='/' class="t-navbar gold-underline _blue _home">Home</router-link to='/'>
      <div class="privacy">
        <div class="_title">
          <div class="t-navbar _upper _red">AndyKarwoski.com</div>
          <div class="t-navbar _upper _blue">Websites & App</div>
          <div class="t-navbar _upper _gold">Privacy Policy</div>
        </div>
        <div class="t-project-details _body">
          By accessing this website we assume you accept these terms and conditions in full. Do not
          continue to use Andy Karwoski's website or applications if you do not accept all of the
          terms and conditions stated on this page. The following terminology applies to these Terms
          and Conditions, Privacy Statement and Disclaimer Notice and any or all Agreements:
          “Client”, “You” and “Your” refers to you, the person accessing this website and accepting
          the Company’s terms and conditions. “The Company”, “Ourselves”, “We”, “Our” and “Us”,
          refers to our Company. “Party”, “Parties”, or “Us”, refers to both the Client and
          ourselves, or either the Client or ourselves. All terms refer to the offer, acceptance and
          consideration of payment necessary to undertake the process of our assistance to the
          Client in the most appropriate manner, whether by formal meetings of a fixed duration, or
          any other means, for the express purpose of meeting the Client’s needs in respect of
          provision of the Company’s stated services/products, in accordance with and subject to,
          prevailing law of Japan. Any use of the above terminology or other words in the singular,
          plural, capitalisation and/or he/she or they, are taken as interchangeable and therefore
          as referring to same.
        </div>
        <div class="t-project-title _blue _section">License</div>
        <div class="t-project-details _body">
          Unless otherwise stated, Andy Karwoski and/or it’s licensors own the intellectual property
          rights for all material on
          <span class="t-project-details _blue">https://andykarwoski.com</span>. All intellectual
          property rights are reserved. You may view and/or print pages from
          <span class="t-project-details _blue">https://andykarwoski.com</span> for your own
          personal use subject to restrictions set in these terms and conditions. You must not:
          Redistribute content from Andy Karwoski (unless content is specifically made for
          redistribution).
        </div>
        <div class="t-project-title _red _section">
          The following organizations may link to our Web site without prior written approval:
        </div>
        <div class="t-project-details _body">
          Government agencies; Search engines; News organizations; Online directory distributors
          when they list us in the directory may link to our Web site in the same manner as they
          hyperlink to the Web sites of other listed businesses; and Systemwide Accredited
          Businesses except soliciting non-profit organizations, charity shopping malls, and charity
          fundraising groups which may not hyperlink to our Web site.
        </div>
        <div class="t-project-details _body">
          These organizations may link to our home page, to publications or to other Web site
          information so long as the link: (a) is not in any way misleading; (b) does not falsely
          imply sponsorship, endorsement or approval of the linking party and its products or
          services; and (c) fits within the context of the linking party's site.
        </div>
        <div class="t-project-details _body">
          We may consider and approve in our sole discretion other link requests from the following
          types of organizations:
        </div>
        <div class="t-project-details _body">
          commonly-known consumer and/or business information sources such as Chambers of Commerce,
          American Automobile Association, AARP and Consumers Union; dot.com community sites;
          associations or other groups representing charities, including charity giving sites,
          online directory distributors; internet portals; accounting, law and consulting firms
          whose primary clients are businesses; and educational institutions and trade associations.
        </div>
        <div class="t-project-details _body">
          We will approve link requests from these organizations if we determine that: (a) the link
          would not reflect unfavorably on us or our accredited businesses (for example, trade
          associations or other organizations representing inherently suspect types of business,
          such as work-at-home opportunities, shall not be allowed to link); (b) the organization
          does not have an unsatisfactory record with us; (c) the benefit to us from the visibility
          associated with the hyperlink outweighs the absence of ; and (d) where the link is in the
          context of general resource information or is otherwise consistent with editorial content
          in a newsletter or similar product furthering the mission of the organization.
        </div>
        <div class="t-project-details _body">
          These organizations may link to our home page, to publications or to other Web site
          information so long as the link: (a) is not in any way misleading; (b) does not falsely
          imply sponsorship, endorsement or approval of the linking party and it products or
          services; and (c) fits within the context of the linking party's site.
        </div>
        <div class="t-project-details _body">
          If you are among the organizations listed in paragraph 2 above and are interested in
          linking to our website, you must notify us by sending an e-mail to info@cine-match.com.
          Please include your name, your organization name, contact information (such as a phone
          number and/or e-mail address) as well as the URL of your site, a list of any URLs from
          which you intend to link to our Web site, and a list of the URL(s) on our site to which
          you would like to link. Allow 2-3 weeks for a response.
        </div>
        <div class="t-project-title _gold _section">
          Approved organizations may hyperlink to our Web site as follows:
        </div>
        <div class="t-project-details _body">
          By use of our corporate name; or By use of the uniform resource locator (Web address)
          being linked to; or By use of any other description of our Web site or material being
          linked to that makes sense within the context and format of content on the linking party's
          site.
        </div>
        <div class="t-project-details _body">
          No use of Andy Karwoski’s logos or other artwork will be allowed for linking absent a
          trademark license agreement.
        </div>
        <div class="t-project-title _blue _section">Reservation of Rights</div>
        <div class="t-project-details _body">
          We reserve the right at any time and in its sole discretion to request that you remove all
          links or any particular link to our Web site. You agree to immediately remove all links to
          our Web site upon such request. We also reserve the right to amend these terms and
          conditions and its linking policy at any time. By continuing to link to our Web site, you
          agree to be bound to and abide by these linking terms and conditions.
        </div>
        <div class="t-project-title _red _section">Removal of links from our website</div>
        <div class="t-project-details _body">
          If you find any link on our Web site or any linked web site objectionable for any reason,
          you may contact us about this. We will consider requests to remove links but will have no
          obligation to do so or to respond directly to you.
        </div>
        <div class="t-project-details _body">
          Whilst we endeavour to ensure that the information on this website is correct, we do not
          warrant its completeness or accuracy; nor do we commit to ensuring that the website
          remains available or that the material on the website is kept up to date.
        </div>
        <div class="t-project-title _gold _section">Content Liability</div>
        <div class="t-project-details _body">
          We shall have no responsibility or liability for any content appearing on your Web site.
          You agree to indemnify and defend us against all claims arising out of or based upon your
          Website. No link(s) may appear on any page on your Web site or within any context
          containing content or materials that may be interpreted as libelous, obscene or criminal,
          or which infringes, otherwise violates, or advocates the infringement or other violation
          of, any third party rights.`
        </div>
        <div class="_copyright t-footer-minor">Copyright © Andy Karwoski 2021</div>
      </div>
    </div>
  </div>
</template>

<script>
import NavBar from '../components/base-components/NavBar.vue'
export default {
  components: { NavBar },
  name: 'Privacy',
  props: {},
  data() {
    return {}
  },
  computed: {},
  methods: {},
}
</script>


<style lang="sass" scoped>
@import '../css/colors.sass'
@import '../css/breakpoints.scss'
.privacy
  position: relative
  margin-top: 6em
  margin-bottom: 4em
  text-align: left
._home
  position: absolute
  left: 0
  top: 0
  margin-top: 1.5em
  margin-left: 1em
  text-align: left
  cursor: pointer
  z-index: 5
  &:hover
    color: $c-red

._title
  text-align: center
  margin-bottom: 4em
._upper
  text-transform: uppercase
._section
  text-align: center
  margin: 1em 0
._body
  padding: 0 2vw
._copyright
  margin-top: 3em
  color: $c-grey-light
  text-align: center
._blue
  color: $c-blue
._red
  color: $c-red
._gold
  color: $c-gold
</style>